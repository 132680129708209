import DashboardLayout from "@/pages/layouts/DashboardLayout";

let forumRoutes = {
  path: '/forums',
  component: DashboardLayout,
  redirect: '/forums/list',
  children: [
    {
      path: 'list',
      name: 'Forum List',
      component: () => import(/* webpackChunkName: "forums" */  '@/pages/forums/ForumList'),
      meta: {
        permissionsCodes: ['forums/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Forum',
      component: () => import(/* webpackChunkName: "forums" */  '@/pages/forums/ForumForm'),
      meta: {
        permissionsCodes: ['forums/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Forum',
      component: () => import(/* webpackChunkName: "forums" */  '@/pages/forums/ForumForm'),
      meta: {
        permissionsCodes: ['forums/update'],
      }
    },
    {
      path: 'subscribers/:id',
      name: 'Show Forum Subscribers',
      component: () => import(/* webpackChunkName: "forums" */  '@/pages/forums/SubscriberList'),
      meta: {
        permissionsCodes: ['forums/subscribers'],
      }
    },
    {
      path: 'manage-gallery/:id',
      name: 'Manage Gallery',
      component: () => import(/* webpackChunkName: "forums" */  '@/pages/forums/GalleryForm'),
      meta: {
        permissionsCodes: ['forums/gallery'],
      }
    }
  ]
}

export default forumRoutes;
