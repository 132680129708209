import DashboardLayout from "@/pages/layouts/DashboardLayout";

let sponsorCategoryRoutes = {
  path: '/sponsor-categories',
  component: DashboardLayout,
  redirect: '/sponsor-categories/list',
  children: [
    {
      path: 'list',
      name: 'Category List',
      component: () => import(/* webpackChunkName: "sponsor-categories" */  '@/pages/sponsors/categories/SponsorCategoryList'),
      meta: {
        permissionsCodes: ['sponsor-categories/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Category',
      component: () => import(/* webpackChunkName: "sponsor-categories" */  '@/pages/sponsors/categories/SponsorCategoryForm'),
      meta: {
        permissionsCodes: ['sponsor-categories/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Category',
      component: () => import(/* webpackChunkName: "sponsor-categories" */  '@/pages/sponsors/categories/SponsorCategoryForm'),
      meta: {
        permissionsCodes: ['sponsor-categories/update'],
      }
    }
  ]
}

export default sponsorCategoryRoutes;
